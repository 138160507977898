<template>
    <b-overlay :show='showLoader'>
        <b-card>
            <b-row>
                <b-col>
                    <label for="schools">{{ $t('general.schools') }}</label>
                    <v-select @input="onSearchAuthorsForSchool" id='schools' :clearable='false' :options='schoolList' label="Name" v-model="selectedSchool"></v-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right mt-1">
                    <b-button variant="primary" @click="onSearchAuthorsForSchool">{{ $t('general.search') }}</b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="authorList">
            <b-row>
                <b-col>
                    <h3>{{ $t("general.author_list") }}</h3>
                </b-col>
                <b-col class="text-right"> <!-- Samo sola lahko dodaja, ureja in briše svoje avtorje in mentorje -->
                    <b-button variant="warning" class='ml-1' @click="$router.push({name: 'admin-add-author', params: {'school_id': selectedSchool.ID}})">{{ $t("general.add_author_for_selected_school") }}</b-button>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <vue-good-table :columns='columns' ref="table" :rows='authorList'
                                    styleClass="vgt-table striped bordered condensed"
                                    :pagination-options="{
                                        enabled: true,
                                        perPage: pageLength
                                    }">

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'ID'">
                                <div class="d-flex justify-content-center">
                                    <b-button class="mr-1" @click="$router.push({name: 'admin-edit-author', params: {author_id: props.row.ID}})" variant="warning"><feather-icon icon="EditIcon"/></b-button>
                                    <b-button @click="onDeleteButtonPressed(props.row.ID)" variant="danger"><feather-icon icon="Trash2Icon"/></b-button>
                                </div>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>

                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >

                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        {{ (($refs.table) ? $refs.table.currentPage : 0) * pageLength - (pageLength - 1) }} - {{ props.total - (($refs.table) ? $refs.table.currentPage : 0) * pageLength > 0 ? (($refs.table) ? $refs.table.currentPage : 0) * pageLength : props.total }} {{ $t('general.of') }} {{ props.total }}
                                    </span>

                                    <b-dropdown
                                        variant="none"
                                        no-caret>

                                        <template v-slot:button-content>
                                            <span>({{ $t('general.per_page') }}: {{pageLength}}) </span>
                                            <feather-icon
                                                icon="ChevronDownIcon"
                                                size="16"
                                                class="text-body align-middle mr-25"
                                            />
                                        </template>

                                        <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                                            {{ length }}
                                        </b-dropdown-item>

                                    </b-dropdown>
                                </div>
                                <div>
                                    <b-pagination
                                        :value="1"
                                        :total-rows="props.total"
                                        :per-page="pageLength"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>

                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-card>

        <b-modal id="confirm-author-deletion-modal" :cancel-disabled="true" hide-footer>
            <div class="d-block text-center">
                <p>{{ $t('general.delete_author_confirmation')}}</p>
                <div>
                    <b-button variant="warning" @click="onRejectDeletion">{{ $t('general.no') }}</b-button>
                    <b-button variant="danger" class="ml-2" @click="onAcceptDeletion">{{ $t('general.yes') }}</b-button>
                </div>
            </div>
        </b-modal>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BPagination, BDropdown, BDropdownItem, BButton, BModal} from 'bootstrap-vue'
    import {VueGoodTable} from 'vue-good-table'
    import vSelect from 'vue-select'
    export default {
        components: {vSelect, BCol, BRow, BOverlay, BCard, BPagination, BDropdown, BDropdownItem, BButton, BModal, VueGoodTable},

        data() {
            return {
                authorList: [],
                showLoader: false,
                columns: [
                    
                    {
                        field: 'Firstname',
                        label: this.$t('general.name'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'Lastname',
                        label: this.$t('general.lastname'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'Email',
                        label: this.$t('general.emailAddress'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'ID',
                        label: '',
                        sortable: false,
                        hidden: false
                    }
                ],

                pageLength: 10,
                lengths: [
                    '10',
                    '20',
                    '50',
                    '100'
                ],
                schoolList: [],
                selectedSchool: null,

                authorDeleteId: 0
            }
        },
        methods: {
            async onSearchAuthorsForSchool() {
                this.authorList = []
                if (!this.selectedSchool || !this.selectedSchool.ID) return

                this.showLoader = true
                try {
                    await this.loadAuthorsForSchool()
                } catch (error) {
                    this.$printError(`${this.$t('general.load_error')}${error.message}`)

                } finally {
                    this.showLoader = false
                }
            },
            setPageLength(length) {
                this.pageLength = length
                this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
            },
            onDeleteButtonPressed(authorId) {
                this.authorDeleteId = authorId
                this.$bvModal.show('confirm-author-deletion-modal')
            },
            onAcceptDeletion() {
                this.deleteAuthor(this.authorDeleteId)
            },
            onRejectDeletion() {
                this.authorDeleteId = 0
                this.$bvModal.hide('confirm-author-deletion-modal')
            },
            deleteAuthor(authorId) {
                const thisIns  = this
                this.$bvModal.hide('confirm-author-deletion-modal')
                thisIns.showLoader = true

                thisIns.$http.delete(`/v1/admin/avtor/${authorId}`)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.author_successfully_deleted')}\n`)
                        this.loadAuthorsForSchool()

                        thisIns.showLoader = false
                        
                    })
                    .catch(error => {
                        const errResponse = error.request.response
                        // constraint error. If author is set to research paper,
                        // then it can't be deleted
                        if (errResponse && errResponse.includes('Error 1451')) {
                            thisIns.$printError(thisIns.$t('general.author_constraint_error'))
                        } else {
                            thisIns.$printError(`${thisIns.$t('general.delete_error')}\n${error.message}`)
                        }
                        thisIns.showLoader = false
                    })
            },
            async loadSchools() {
                this.showLoader = true
                try {
                    const schoolsResponse = await this.$http.get('/v1/admin/sola')
                    this.schoolList = schoolsResponse.data || 0

                    if (this.schoolList.length > 0) {
                        this.selectedSchool = this.schoolList[0]
                        this.loadAuthorsForSchool()
                    }

                } catch (error) {
                    this.$printError(`${this.$t('general.load_error')}${error.message}`)

                } finally {
                    this.showLoader = false

                }
                this.showLoader = false
            },
            loadAuthorsForSchool() {
                const thisIns = this
                thisIns.showLoader = true
                this.authorList = []

                thisIns.$http.get(`/v1/admin/avtorji/${this.selectedSchool.ID}`)
                    .then(res => {
                        if (res.data) this.authorList = res.data  || []
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        async mounted() {
            await this.loadSchools()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
